import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Manifest = () => (
  <Layout>
    <SEO title="Manifest" />
    <div class="styledtext layout__container--med">
      <h3>Manifest</h3>

      <p>U ime Krhkosti… <br/>
        … skakat ću bez padobrana u vlastitu nutrinu kako bih osjetila silinu, ljepotu i stravu slobodnoga pada. 
      </p>
  
      <p>
        U ime Istine… <br/>
        … nikada se neću ispričavati za ono što jesam i za ono što nisam; za ono što osjećam i za ono što ne osjećam. 
      </p>
  
      <p>
        U ime Balansa… <br />
        … osigurat ću si dovoljno tišine i samoće kako bih povezala svoj unutarnji svijet s vanjskim svijetom i uspostavila balans između ta dva svijeta koji čine moju stvarnost.
      </p>
  
      <p>
        U ime Sna… <br />
        …istražit ću svaki zakutak svoga Duha i oslobađati ga društvenih uvjerenja i ograničenja umausmjerenoga zakonima bića društva. Zbilju ću mijenjati snom o biću prirode - slobodnom, laganom, uzbuđenom, kreativnom.
      </p>
  
      <p>
        U ime Života… <br />
        … prethodnih, sadašnjih i budućih iskustava životnoga ciklusa, prepuštat ću se iskustvima sa sviješću da je istinski smisao pronaći sebe u svijetu i svjetove u sebi.
      </p>
  
      <p>
        U ime Ljubavi… <br />
        … otvorit ću srce za duboku interakciju s ljudima, osvještavajući prethodne dogovore duša da zajedničkim iskustvima ekspandiraju, mijenjaju se i dijele određene etape životnog puta. Dopustit ću ljudima da dođu, dopustit ću ljudima da ostanu i dopustit ću ljudima da odu. Dopustit ću sebi da volim i da ne volim, uživajući u paleti emocija kroz dolaske, ostanke i odlaske ljudi od kojih će svatko biti jedna nit u tkanju koje tkam.
      </p>
  
      <p>
        U ime Smrti… <br />
        … osvijestit ću ovozemaljsku prolaznost svih i svega kroz slatkoću Sadašnjeg Trenutka kao jedine istinske istine koju posjedujem. Sa sviješću da ću - i kada prestanem biti ovdje i sada - zauvijek biti svuda, pisat ću svoju dušu po papiru kao da mi život o tome ovisi, kao da pisati znači disati. 
      </p>
    </div>
  </Layout>
)

export default Manifest
